export const formatCashValue = (price) => {
    if (!price){
      return price;
    }
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return USDollar.format(price)
}

export const formatFixedNumber = (c, prepend="", postpend="", decimal=0) => {
  if(isNaN(parseFloat(c)) == false){
    return prepend + parseFloat(c).toFixed(decimal) + postpend
  }
  return c
}

export const booleanToggle = (b) => {
  return !(b ?? false);
}

export const markSignalBar = (precent) => {
  precent = parseFloat(precent)
  // extra-weak, weak, medium, strong, super-strong
  let css_strength = ''
  if( precent >= 0 && precent <= 21){
      css_strength = "extra-weak";
  }
  
  if( precent >= 21 && precent <= 40){
      css_strength = "weak";
  }

  if( precent >= 40 && precent <= 80){
      css_strength = "medium";
  }

  if( precent >= 80 && precent <= 90){
      css_strength = "strong";
  }

  if( precent >= 90 && precent <= 100){
      css_strength = "super-strong";
  }

  return css_strength
}